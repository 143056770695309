import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component, OnInit, OnDestroy, NgZone, ElementRef, Renderer2 } from '@angular/core'
import { HttpRequestService } from '../drive-services/http-request.service'
import { VerifyAccessService } from '../configure-app/connect-to-drive/verify-access.service'
import { catchError, retry, first, elementAt } from 'rxjs/operators';
import { Observable, of, Subscription, Subject } from 'rxjs';
import { SharedMenuService } from '../services/shared-menu.service'
import { StoreFileService, fileEntryDetails } from '../drive-services/store-file.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router'
import { MatTableDataSource } from '@angular/material/table';
import { popupFilterText } from './popup-filter-text'
import { MatDialog } from '@angular/material/dialog';
import { UpdatePwaService } from '../drive-services/update-pwa.service'
import { environment, msalConfiguration } from 'src/environments/environment'
import { tools } from '../services/tools';


@Component({
  selector: 'app-display-files',
  templateUrl: './display-files.component.html',
  styleUrls: ['./display-files.component.scss']
})
export class DisplayFilesComponent implements OnInit, OnDestroy {


  text: string;
  dislapyJson: string
  baseUrl = "https://graph.microsoft.com/v1.0/me/drive/"
  public isConfigurationValid: boolean = false;
  public isLoaded: boolean = false
  public listOfFiles: any[] = [];

  private currentPathSubscription: Subscription;
  private currentPath: string = "";
  public pathHistory: string[] = [];
  private currentFolderSource = new Subject<string>();
  currentFolder$ = this.currentFolderSource.asObservable();

  private paramSubscription: Subscription;
  private rootAppFolderReaded: string;

  private downloadEvolutionSubscription: Subscription;
  public errorConvertionFile: boolean = false;
  public convertionFileContent: any[] = [];
  public waitForDownload: boolean = true;
  public downloaderCounter: number = 0;

  public fileEntryDetails: Map<string, fileEntryDetails>;
  public transfertProgression: Map<string, Number>;
  public fileListForTemplate: Map<string, any>;
  public folderListForTemplate: Map<string, string>;
  public dataSource = new MatTableDataSource();



  constructor(private httpRequestService: HttpRequestService,
    private sharedMenuService: SharedMenuService,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private el: ElementRef,
    private dialog: MatDialog,
    private tools: tools,
    private renderer: Renderer2,
    private verifyAccessService: VerifyAccessService,
    private fileService: StoreFileService) {

    //update current path on folder change
    this.currentPathSubscription = this.currentFolder$.subscribe(
      value => {
        this.currentPath = value;
        this.transfertProgression = new Map<string, number>();
        this.sharedMenuService.setLoadingState(true);
        this.waitForDownload = true;
        this.downloaderCounter = 0
        this.getRemoteListOfFiles();
      }
    )

    this.renderer.listen(this.el.nativeElement, 'backbutton', (event) => {
      if (!(this.downloaderCounter > 10))
        this.parentDirectory();
    });

    this.downloadEvolutionSubscription = this.fileService.fileDownloadEvolution$.subscribe(
      value => {
        this.transfertProgression.set(value.uidFile, value.pourcentage)
      }
    )
  }

  public setcurrentFolder(currentFolder: string) {
    this.sharedMenuService.setLoadingState(true);
    this.currentFolderSource.next(currentFolder);
  }

  ngOnDestroy() {
    this.currentPathSubscription.unsubscribe();
    // this.fileEntryDetailsSubscription.unsubscribe();
    this.downloadEvolutionSubscription.unsubscribe();
    this.paramSubscription.unsubscribe();
  }

  ngOnInit() {

    this.sharedMenuService.setMenuIcon("menu");
    this.sharedMenuService.setLoadingState(true);
    this.paramSubscription = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        let pathFromUrl = params['pathId'] || "";
        console.log("param pathID in displayfiles : " + pathFromUrl)
        if (pathFromUrl != null && pathFromUrl != "") {
          this.waitForDownload = true;
          this.downloaderCounter = 0
          this.currentPath = pathFromUrl;
          let appTitle = this.fileService.getFileEntryDetails(pathFromUrl)
          if (appTitle != null && appTitle.name != null && appTitle.name != "")
            this.sharedMenuService.setAppTitle(appTitle.name)
          this.isConfigurationValid = true;
          this.isLoaded = true;
          console.log("get remote list of file call in init (with paramter)" + pathFromUrl)
          this.getRemoteListOfFiles()
        }
        else {
          this.pathHistory = [];
          this.sharedMenuService.setLoadingState(true);
          this.resetDownloadingState();
          this.verifyAccessService.verifyAcces()
            .subscribe(result => {
              if (result == true) {
                this.isConfigurationValid = true;
                this.isLoaded = true;
                this.initialiseRootFolder();
              }
              else if (result == false) {
                this.isLoaded = true;
                this.isConfigurationValid = false
              }
              else {
                this.isLoaded = true;
                this.isConfigurationValid = true
                this.initialiseRootFolder();
              }
            })
        }
      });

    this.dataSource.filterPredicate = function (data: any, filter: string): boolean {
      return data.name.toLowerCase().includes(filter);
    };
  }

  private initialiseRootFolder(inputPath?: string) {

    if (inputPath != null && inputPath != "")
      this.currentPath = inputPath;

    if (this.currentPath != "")
      this.sharedMenuService.setAppTitle("Mes fichiers")

    let pathFromLocal = "";
    this.subInitialiseRootFolder(pathFromLocal);

  }

  private subInitialiseRootFolder(pathFromLocal: string) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    this.httpRequestService.getData(msalConfiguration.rootAppFolderUrl, httpOptions)
      .pipe(
        first()).subscribe(
          result => {
            //store rootPathResultID
            if (result != null && result.id != null) {
              this.rootAppFolderReaded == result.id
              this.fileService.storeFileString("rootPathId", result.id)
                .pipe(
                  first()).subscribe(
                    resultStore => {
                      this.setcurrentFolder(result.id);
                      this.pathHistory.push(result.id);
                    })
            }
            else if (result != null && result.ok != null && result.ok == false && result.name != null && result.name.includes("HttpErrorResponse")) {
              this.fileService.getfileEntry("rootPathId")
                .pipe(
                  first()).subscribe(
                    resp => {
                      if (resp != null) {
                        this.rootAppFolderReaded == resp
                        this.setcurrentFolder(resp);
                        this.pathHistory.push(resp);
                        pathFromLocal = resp
                      }
                    })

            }
          })
  }

  private readConversionFile(readOnlyFromLocal?: boolean) {
    //current Path => this.currentPath
    //generate file currentPath.convert
    let repInternalUseId = ""
    this.errorConvertionFile = false;
    //first readLocalFileConvertion
    let filedetails: Map<string, fileEntryDetails> = this.fileService.getfileEntryArray();
    let internalUseOnlyPathId: string = ""
    let currentConvertionFileId: string = ""
    filedetails.forEach((Element, key) => {
      if (Element.parentId == this.currentPath)
        if (Element.name.toLowerCase() == "internaluseonly")
          internalUseOnlyPathId = key;
    });

    if (internalUseOnlyPathId != "") {
      filedetails.forEach((Element, key) => {
        if (Element.parentId == internalUseOnlyPathId)
          if (Element.name.toLowerCase().includes("conversion"))
            currentConvertionFileId = key;
      });

      if (currentConvertionFileId != "") {
        this.fileService.readFileFromLocalPath(currentConvertionFileId)
          .pipe(
            first()).subscribe(
              fileContent => {
                this.parseFileConvertion(fileContent);
              })
      }
    }

    if (readOnlyFromLocal)
      return;

    for (let i = 0; i < this.listOfFiles.length; i++) {
      if (this.listOfFiles[i].folder != null && this.listOfFiles[i].name.toLowerCase() == "internaluseonly") {
        repInternalUseId = this.listOfFiles[i].id
        break;
      }
    }
    if (repInternalUseId != "") {
      let urlAcces = this.baseUrl + "items/" + repInternalUseId + "/children"
      //if token valid
      this.subReadConversionFile(urlAcces);
    }
  }

  private subReadConversionFile(urlAcces: string) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
      })
    };

    this.httpRequestService.getData(urlAcces, httpOptions)
      .pipe(
        first()).subscribe(result => {
          let subFiles = result.value;
          let fileConvertion: any
          if (result?.name?.includes("HttpErrorResponse")) {
            return
          }
          else {
            if (subFiles != null) {
              for (let i = 0; i < subFiles.length; i++) {
                if (subFiles[i].file != null && subFiles[i].name.toLowerCase().includes("conversion")) {
                  fileConvertion = subFiles[i];
                  break;
                }
              }
            }
            if (fileConvertion != null) {
              let filedetails: fileEntryDetails = this.fileService.getFileEntryDetails(fileConvertion.id);
              if (filedetails == null || (new Date(filedetails.lastModification) < new Date(fileConvertion.lastModifiedDateTime)) || filedetails.downloadState == 1) {
                if (filedetails == null || filedetails.downloadState == 1) {
                  this.fileService.downloadFile(fileConvertion.id,
                    fileConvertion['@microsoft.graph.downloadUrl'],
                    new Date(fileConvertion.lastModifiedDateTime),
                    fileConvertion.parentReference.id,
                    fileConvertion.name)
                    .pipe(
                      first()).subscribe(
                        result => {
                          if (result == true) {
                            this.fileService.readFileFromLocalPath(fileConvertion.id)
                              .pipe(
                                first()).subscribe(
                                  fileContent => {
                                    this.parseFileConvertion(fileContent);
                                  })
                          }
                        })
                }
                else {
                  this.fileService.updatesFile(fileConvertion.id,
                    fileConvertion['@microsoft.graph.downloadUrl'],
                    new Date(fileConvertion.lastModifiedDateTime),
                    fileConvertion.parentReference.id,
                    fileConvertion.name)
                    .pipe(
                      first()).subscribe(
                        result => {
                          if (result == true) {
                            this.fileService.readFileFromLocalPath(fileConvertion.id)
                              .pipe(
                                first()).subscribe(
                                  fileContent => {
                                    this.parseFileConvertion(fileContent);
                                  })
                          }
                        })
                }
              }
            }
            else {
              this.errorConvertionFile = true
            }
          }
        })
  }

  private readThemesFile() {
    return;
    //current Path => this.currentPath
    //generate file currentPath.theme1
    //generate file currentPath.theme2
    let repInternalUseId = ""

    for (let i = 0; i < this.listOfFiles.length; i++) {
      if (this.listOfFiles[i].folder != null && this.listOfFiles[i].name.toLowerCase() == "internaluseonly") {
        repInternalUseId = this.listOfFiles[i].id
        break;
      }
    }
    if (repInternalUseId != "") {
      let urlAcces = this.baseUrl + "items/" + repInternalUseId + "/children"
      this.httpRequestService.getData(urlAcces)
        .pipe(
          first()).subscribe(result => {
            let subFiles = result.value;
            if (subFiles == null)
              return;
            for (let i = 0; i < subFiles.length; i++) {
              let fileTheme: any
              if (subFiles[i].file != null && subFiles[i].name.includes("theme")) {
                fileTheme = subFiles[i];
                if (fileTheme != null) {
                  let filedetails: fileEntryDetails = this.fileService.getFileEntryDetails(fileTheme.id);
                  if (filedetails == null || filedetails.downloadState == 1) {
                    this.fileService.downloadFile(fileTheme.id,
                      fileTheme['@microsoft.graph.downloadUrl'],
                      new Date(fileTheme.lastModifiedDateTime),
                      fileTheme.parentReference.id,
                      fileTheme.name)
                  }
                  else {
                    this.fileService.updatesFile(fileTheme.id,
                      fileTheme['@microsoft.graph.downloadUrl'],
                      new Date(fileTheme.lastModifiedDateTime),
                      fileTheme.parentReference.id,
                      fileTheme.name)

                  }
                }
              }
            }
          })
    }

  }

  //convertionFileContent
  private parseFileConvertion(fileContent: string) {
    this.convertionFileContent = [];
    let lines: string[] = []
    lines = fileContent?.split('\n');
    for (let i = 0; i < lines?.length; i++) {
      let item = lines[i]?.split('=');
      let itemName = item[0];
      let itemValues = item[1];
      let values = itemValues?.split('\t')
      let libelle = null
      if (values[0] != null) libelle = values[0].replace(/(\r\n|\n|\r)/gm, "")
      let typeControl = null
      if (values[1] != null) typeControl = values[1].replace(/(\r\n|\n|\r)/gm, "")
      let style = null
      if (values[2] != null) style = values[2].replace(/(\r\n|\n|\r)/gm, "")
      let controlOption = null
      if (values[3] != null) controlOption = values[3].replace(/(\r\n|\n|\r)/gm, "")
      let valuesParsed: convertionElem =
      {
        libelle: libelle,
        typeControl: typeControl,
        style: style,
        controlOption: controlOption
      }
      this.convertionFileContent.push(JSON.stringify({ "itemLine": itemName, "valuesParsed": valuesParsed }))
    }
  }

  private updateDownloadState(fileName: string, downloadState: number, file) {

    let idx = -1
    idx = this.dataSource.data.findIndex((i: any) => i.file.name === fileName);
    if (idx != -1) {
      let data: any = this.dataSource.data[idx]
      data.downloadState = downloadState
      this.dataSource.data[idx] = data;
    }
    if (this.downloaderCounter < 1) {
      this.waitForDownload = false;
      this.generateHtmlObject();
    }
  }

  private resetDownloadingState() {
    //reset files in downloading states
    let updateNedded: boolean = false
    let fileEntryArray: Map<string, fileEntryDetails> = this.fileService.getfileEntryArray();

    fileEntryArray.forEach((element, key) => {
      if (element.downloadState == 2) {
        element.downloadState = 1
        updateNedded = true;
      }
    });
  }

  private async storeFiles() {
    if (this.listOfFiles == null || this.listOfFiles.length == 0) {
      this.waitForDownload = false;
      return;
    }

    if (this.downloaderCounter < 10) {
      this.waitForDownload = false;
      this.generateHtmlObject();
    }
    for (let i = 0; i < this.listOfFiles.length; i++) {
      let filedetails: fileEntryDetails = this.fileService.getFileEntryDetails(this.listOfFiles[i].id);
      if (this.listOfFiles[i].file != null && this.listOfFiles[i].name.includes(".json")) {
        if (filedetails == null || (new Date(filedetails.lastModification) < new Date(this.listOfFiles[i].lastModifiedDateTime)) || filedetails.downloadState == 1) {
          let fe: fileEntryDetails =
          {
            downloadState: 2,
            lastModification: new Date(this.listOfFiles[i].lastModifiedDateTime),
            parentId: this.listOfFiles[i].parentReference.id,
            fileType: 1,
            name: this.listOfFiles[i].name
          }
          this.fileService.insertUpdateOneEntry(this.listOfFiles[i].id, fe);
          this.updateDownloadState(this.listOfFiles[i].name, 2, this.listOfFiles[i]);
          if (filedetails == null || filedetails.downloadState == 1) {
            await this.fileService.downloadFile(this.listOfFiles[i].id,
              this.listOfFiles[i]["@microsoft.graph.downloadUrl"],
              new Date(this.listOfFiles[i].lastModifiedDateTime),
              this.listOfFiles[i].parentReference.id,
              this.listOfFiles[i].name).pipe(
                first()).subscribe(
                  result => {
                    if (result == false) {
                      //reinitialise download state
                      this.downloaderCounter--;
                      let fe: fileEntryDetails =
                      {
                        downloadState: 1,
                        lastModification: new Date(this.listOfFiles[i].lastModifiedDateTime),
                        parentId: this.listOfFiles[i].parentReference.id,
                        fileType: 1,
                        name: this.listOfFiles[i].name
                      }
                      this.updateDownloadState(this.listOfFiles[i].name, 1, this.listOfFiles[i])
                      this.fileService.insertUpdateOneEntry(this.listOfFiles[i].id, fe);
                      if (this.listOfFiles[i] != null)
                        this.transfertProgression.set(this.listOfFiles[i].id, -1);
                    }
                    else {
                      this.downloaderCounter--;
                      this.updateDownloadState(this.listOfFiles[i].name, 3, this.listOfFiles[i])
                    }
                  })
          }
          else
            await this.fileService.updatesFile(this.listOfFiles[i].id,
              this.listOfFiles[i]['@microsoft.graph.downloadUrl'],
              new Date(this.listOfFiles[i].lastModifiedDateTime),
              this.listOfFiles[i].parentReference.id,
              this.listOfFiles[i].name)
              .pipe(
                first()).subscribe(
                  result => {
                    if (result == false) {
                      //reinitialise download state
                      this.downloaderCounter--;
                      let fe: fileEntryDetails =
                      {
                        downloadState: 1,
                        lastModification: new Date(this.listOfFiles[i].lastModifiedDateTime),
                        parentId: this.listOfFiles[i].parentReference.id,
                        fileType: 1,
                        name: this.listOfFiles[i].name
                      }
                      this.fileService.insertUpdateOneEntry(this.listOfFiles[i].id, fe);
                      if (this.listOfFiles[i] != null)
                        this.transfertProgression.set(this.listOfFiles[i].id, -1);
                    }
                    else {
                      this.downloaderCounter--;
                      this.updateDownloadState(this.listOfFiles[i].name, 3, this.listOfFiles[i])
                    }
                  })
        }
      }
      else if (this.listOfFiles[i].folder != null) {
        if (filedetails == null || filedetails.lastModification < new Date(this.listOfFiles[i].lastModifiedDateTime)) {
          let fe: fileEntryDetails =
          {
            downloadState: 3,
            lastModification: new Date(this.listOfFiles[i].lastModifiedDateTime),
            parentId: this.listOfFiles[i].parentReference.id,
            fileType: 2,
            name: this.listOfFiles[i].name
          }
          this.fileService.insertUpdateOneEntry(this.listOfFiles[i].id, fe);
        }
      }
    }

  }

  private determineDelta() {
    this.fileService.getfileEntry("deltaToken")
      .pipe(
        first()).subscribe(
          resp => {
            if (resp != null) {
              let deltaLink: string
              if (resp != null)
                deltaLink = resp
              else
                deltaLink = msalConfiguration.deltaAppFolderUrl

              this.updateDeltaToken(deltaLink);
            }
            else
              this.updateDeltaToken(msalConfiguration.deltaAppFolderUrl)
          })
  }

  private updateDeltaToken(url: string) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    let futurDeltaLink: string = null;
    let nextDeltalink: string = null;


    this.httpRequestService.getData(url, httpOptions)
      .pipe(
        first()).subscribe(
          result => {
            let countUpdatesForCurrentDirectory: number = 0
            if (result != null && result.value != null) {
              for (let i = 0; i < result.value.length; i++) {
                countUpdatesForCurrentDirectory++;
                if (result.value[i].deleted != null && result.value[i].deleted.state != null && result.value[i].deleted.state == "deleted") {
                  let idToRemove = result.value[i].id
                  for (let j = 0; j < this.listOfFiles.length; j++) {
                    if (this.listOfFiles[j].id == idToRemove)
                      this.listOfFiles.splice(j, 1)
                  }
                  try {
                    this.fileService.removeFile(idToRemove);
                  }
                  catch
                  {
                    console.log("file does not exist in fileService")
                  }
                }
              }
            }
            if (countUpdatesForCurrentDirectory < 10) {
              // this.waitForDownload = false;
              this.generateHtmlObject();
            }
            if (result['@odata.nextLink'] != null && result['@odata.nextLink'] != "")
              nextDeltalink = result['@odata.nextLink']
            if (result['@odata.deltaLink'] != null && result['@odata.deltaLink'] != "")
              futurDeltaLink = result['@odata.deltaLink']

            if (result?.ok == false && result?.name?.includes("HttpErrorResponse")) {
              //offline mode
              this.waitForDownload = false;
            }
            if (futurDeltaLink != null) {
              this.fileService.storeFileString("deltaToken", futurDeltaLink)
                .subscribe({
                  next: (mapFileEntrySaveResult) => { console.log("SUCCES STORAGE DELTA LINK: " + mapFileEntrySaveResult) },
                  error: (mapFileEntrySaveResult) => { console.log("ERROR STORAGE : " + mapFileEntrySaveResult) }
                })
            }
            if (nextDeltalink != null)
              this.updateDeltaToken(nextDeltalink);
          })
  }

  public getRemoteListOfFiles() {
    /* ----------------------*/
    //first read local FE for path
    /* ----------------------*/
    let urlAcces: string;
    if (this.currentPath == ("" || undefined || null))
      urlAcces = this.baseUrl + "/special/approot/";
    else
      urlAcces = this.baseUrl + "items/" + this.currentPath + "/children"

    //If pathHistory == null <=> first time openning App => read rootPathId
    if (this.pathHistory == null || this.pathHistory.length == 0) {
      this.fileService.getfileEntry("rootPathId")
        .pipe(
          first()).subscribe(
            resp => {
              if (resp != null) {
                let pathCalculated = this.currentPath;
                while (pathCalculated != resp) {
                  //find parrent path
                  this.pathHistory.unshift(pathCalculated);
                  let feDetail = this.fileService.getFileEntryDetails(pathCalculated)
                  pathCalculated = feDetail.parentId;
                }
                if (this.currentPath != resp)
                  this.pathHistory.unshift(resp);
              }
            })
    }

    /* ----------------------*/
    //first read local FE for file
    /* ----------------------*/
    let fileEntryArray: Map<string, fileEntryDetails> = this.fileService.getfileEntryArray()
    this.fileEntryDetails = fileEntryArray;
    let localListOfFiles: any[] = [];
    fileEntryArray.forEach((element, key) => {
      if (element.parentId == this.currentPath) {
        let localElem: any
        if (element.fileType == 1)
          localElem = { "id": key, "name": element.name, "file": true }
        else
          localElem = { "id": key, "name": element.name, "folder": true }
        localListOfFiles.push(localElem)
      }
    })
    this.listOfFiles = localListOfFiles
    this.generateHtmlObject()

    /* ----------------------*/
    //then read listoffFiles from online file
    /* ----------------------*/
    this.readConversionFile();
    this.readThemesFile();
    this.determineDelta();
    this.downloaderCounter = 0;
    this.getRemoteListOfFilesRecursive(urlAcces)
  }

  public getRemoteListOfFilesRecursive(urlAcces: string) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
      })
    };

    /* ----------------------------------------  */
    //download or update list of files description
    /* ----------------------------------------  */
    this.httpRequestService.getData(urlAcces, httpOptions)
      .pipe(
        first()).subscribe(resultSub => {
          this.sharedMenuService.setLoadingState(true);
          if (resultSub.name == null || (!resultSub.name.includes("HttpError"))) {
            this.listOfFilesUpdate(resultSub.value)
            this.storeFiles();
          }
          if (resultSub['@odata.nextLink'] != null && resultSub['@odata.nextLink'] != "") {
            this.getRemoteListOfFilesRecursive(resultSub['@odata.nextLink'])
          }
          if (resultSub != null && resultSub.ok != null && resultSub.ok == false && resultSub.name != null && resultSub.name.includes("HttpErrorResponse")) {
            //offline mode
            this.waitForDownload = false;
          }
        }),
      catchError(e => {
        return new Observable((observer) => {
          observer.next(false)
          observer.complete()
        });
      })

  }

  public openFile(file: any, downloadState: number) {
    console.log("openFile")
    if (this.convertionFileContent?.length > 0)
      this.openFileInternal(file, downloadState)
    else {
      //Dirty method
      this.readConversionFile()
      this.tools.delay(300).then(res => {
        if (this.convertionFileContent?.length > 0)
          this.openFileInternal(file, downloadState)
        else
          this.openFile(file,downloadState)
      })
    }
  }

  private openFileInternal(file: any, downloadState: number) {
    let navigationExtras: NavigationExtras =
    {
      queryParams:
      {
        'fileId': file.id,
        'convertionObject': this.convertionFileContent
      }
    }

    if (downloadState == 3) {
      this.sharedMenuService.setAppTitle((file.name).slice(0, (file.name).lastIndexOf('.')));
      if ((file.name).slice((file.name).lastIndexOf('.')).includes("json"))
        this.router.navigate(['/display-json-web-page'], navigationExtras)
      else
        this.openUnknowFile(file);
    }
    else {
      //find file using fileId
      let fileToDownload: any
      for (let i = 0; i < this.listOfFiles.length; i++) {
        if (this.listOfFiles[i].id == file.id) {
          fileToDownload = this.listOfFiles[i]
          break;
        }
      }

      if (fileToDownload == null) {
        return
      }
      this.fileService.downloadFileChild(fileToDownload.id,
        fileToDownload['@microsoft.graph.downloadUrl'],
        fileToDownload.lastModifiedDateTime,
        fileToDownload.parentReference.id,
        fileToDownload.name)
        .pipe(
          first()).subscribe(result => {
            this.sharedMenuService.setAppTitle((fileToDownload.name).slice(0, (fileToDownload.name).lastIndexOf('.')));
            if ((fileToDownload.name).slice((fileToDownload.name).lastIndexOf('.')).includes("json"))
              this.router.navigate(['/display-json-web-page'], navigationExtras)
            else
              this.openUnknowFile(fileToDownload);
          })
    }
  }

  public openUnknowFile(file: any) {
    //TODO
    return;
  }

  public chidrenDirectory(id: string) {

    if (this.pathHistory[this.pathHistory.length - 1] != id) {
      this.sharedMenuService.setLoadingState(true);
      this.pathHistory.push(id);
      // this.setcurrentFolder(id);
      let folderFileEntryDetails: fileEntryDetails = this.fileService.getFileEntryDetails(id)
      if (folderFileEntryDetails.name != null && folderFileEntryDetails.name != "")
        this.sharedMenuService.setAppTitle(folderFileEntryDetails.name);
      if (id != null) {
        this.router.navigate(['/home'], { queryParams: { pathId: id } });
      }
    }
  }

  public parentDirectory() {
    this.pathHistory.pop()
    let id = this.pathHistory[this.pathHistory.length - 1]
    // this.setcurrentFolder(this.pathHistory[this.pathHistory.length - 1]);
    let folderFileEntryDetails: fileEntryDetails = this.fileService.getFileEntryDetails(id)
    if (folderFileEntryDetails == null)
      this.sharedMenuService.setAppTitle("Mes fichiers")
    else if (folderFileEntryDetails.name != null && folderFileEntryDetails.name != "")
      this.sharedMenuService.setAppTitle(folderFileEntryDetails.name)
    this.sharedMenuService.setLoadingState(true);
    this.ngZone.run(() => this.router.navigate(['/home'], { queryParams: { pathId: this.pathHistory[this.pathHistory.length - 1] } })).then();
  }

  public createAppFolder() {

    let content = {
      "name": "themes",
      "folder": {},
      "@microsoft.graph.conflictBehavior": "rename"
    }
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    this.httpRequestService.postData(msalConfiguration.rootAppFolderUrl + "/children", content, httpOptions)
      .pipe(
        first()).subscribe(
          result => {
            this.setcurrentFolder(result.id);
          })
  }

  private listOfFilesUpdate(file: any) {
    let newItem: Boolean;
    if (file == null)
      return;
    for (let j = 0; j < file.length; j++) {
      newItem = true;
      let filedetails: fileEntryDetails = this.fileService.getFileEntryDetails(file[j].id);
      for (let i = 0; i < this.listOfFiles.length; i++) {
        if (this.listOfFiles[i].id == file[j].id) {
          this.listOfFiles[i] = file[j];
          newItem = false;
        }
      }
      if (newItem) {
        this.listOfFiles.push(file[j]);
      }
      if (file[j].file != null && file[j].name.includes(".json")) {
        if (filedetails == null || (new Date(filedetails.lastModification) < new Date(file[j].lastModifiedDateTime)) || filedetails.downloadState == 1) {
          this.downloaderCounter++;
        }
      }
      if (file[j] != null && file[j].name != null && file[j].name.toLowerCase() == "internaluseonly") {
        this.readConversionFile();
        this.readThemesFile();
      }
    }
    this.generateHtmlObject()
  }

  private generateHtmlObject() {
    // return;
    let mapItemFiles: fileList[] = [];
    let mapFolderList = new Map();
    for (let i = 0; i < this.listOfFiles.length; i++) {
      if (this.listOfFiles[i].parentReference == null || (this.listOfFiles[i].parentReference.id == this.currentPath)) {
        if (this.listOfFiles[i].name.toLowerCase().includes(".json") && this.listOfFiles[i].file != null && !this.waitForDownload) {
          let downloadState = 1
          if (this.fileEntryDetails.get(this.listOfFiles[i].id) != null)
            downloadState = this.fileEntryDetails.get(this.listOfFiles[i].id).downloadState;
          let index = -1
          for (let i = 0; i < mapItemFiles.length; i++) {
            if (mapItemFiles[i].name == this.listOfFiles[i].name)
              index = i;
          }
          let endOfStrName = this.listOfFiles[i].name.indexOf('.json')
          let displayName = this.listOfFiles[i].name
          if (endOfStrName != -1)
            displayName = displayName.substr(0, endOfStrName)

          if (index > 0)
            mapItemFiles[i] = { "name": displayName, "downloadState": downloadState, file: this.listOfFiles[i] }
          else
            mapItemFiles.push({ "name": displayName, "downloadState": downloadState, file: this.listOfFiles[i] })




        }
        if (this.listOfFiles[i] != null &&
          this.listOfFiles[i].folder != null &&
          this.listOfFiles[i].name != "" &&
          this.listOfFiles[i].name.toLowerCase() != "internaluseonly")
          mapFolderList.set(this.listOfFiles[i].name, this.listOfFiles[i].id)
      }
      this.sharedMenuService.setLoadingState(false);
    }
    let temporaryMapFile = new Map(mapItemFiles.map((i: any) => [i.name, i]));
    let temporaryArrayFile = new Map([...temporaryMapFile.entries()].sort())
    let arraySorted = [...temporaryArrayFile.values()];
    this.dataSource.data = arraySorted;

    this.folderListForTemplate = new Map([...mapFolderList.entries()].sort())
    if (this.folderListForTemplate.size > 0)
      this.sharedMenuService.setLoadingState(false);

  }


  public applySearch(): void {
    if (this.downloaderCounter > 1)
      return
    const dialogRef = this.dialog.open(popupFilterText, {
      width: '250px',
      data: { inputFilter: this.dataSource.filter }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.dataSource.filter = result.trim().toLowerCase();;

    });
  }

  public applyFilter(): void {

    console.log('filter option in developpement');

    return;
    const dialogRef = this.dialog.open(popupFilterText, {
      width: '250px',
      data: { inputFilter: this.dataSource.filter }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.dataSource.filter = result.trim().toLowerCase();;

    });
  }

}

export interface fileList {
  name: string;
  downloadState: number;
  file: any;
}


export interface convertionElem {
  libelle: string,
  typeControl: string,
  style: string
  controlOption: string[]
}

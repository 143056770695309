<div *ngIf="isLoaded ; else loading">
  <div *ngIf="!isConfigurationValid" class="simple-text-container">
    L'application à besoin d'accéder à votre oneDrive <br />
    <button mat-raised-button color="primary" class="font-size-12-margin-35"
      [routerLink]="['/configure-app']">Configurer l'applicatif</button>
  </div>
  <div *ngIf="errorConvertionFile" class="font-size-13">
    Impossible de lire le fichier de configuration de l'application ! Contacter voter administrateur
  </div>
  <div *ngIf="pathHistory.length>1 && !(downloaderCounter > 10)" (click)="parentDirectory()" class="itemFileFolder">
    <span>
      <mat-icon aria-label="Menu" class="material-icons">arrow_back</mat-icon>
    </span>
    <span class="file-name">
      Dossier parent
    </span>
  </div>
  <ng-container *ngIf="!( downloaderCounter > 10)">
    <div *ngFor="let itemFolder of folderListForTemplate | keyvalue" (click)="chidrenDirectory(itemFolder.value)"
      class="itemFileFolder">
      <span>
        <mat-icon aria-label="Menu" class="material-icons icon-primary-color">folder</mat-icon>
      </span>
      <span class="file-name">
        {{itemFolder.key}}
      </span>
    </div>
  </ng-container>
  <div class="top-right-button">
    <mat-icon aria-label="filter" class="material-icons filter-button" (click)=applyFilter();>filter_list</mat-icon>
    <mat-icon aria-label="search" class="material-icons search-button" (click)=applySearch();>search</mat-icon>
  </div>

  <div *ngIf=" downloaderCounter > 10" class="simple-text-container" style="font-style : italic">
    veuillez patienter pendant la mise à jour de vos fichiers. <br /><br />
    <b>Fichiers restants à télécharger : {{downloaderCounter}}</b>
  </div>

  <table *ngIf="dataSource.data.length > 0 && !(downloaderCounter > 10)" mat-table [dataSource]="dataSource"
    class="mat-elevation-z8 array-list-of-files">
    <!-- Name -->
    <ng-container matColumnDef="name">
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
    <!-- downloadState -->
    <ng-container matColumnDef="downloadState">
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.downloadState == 2" class="icon-primary-color">
          <mat-icon aria-label="Menu" class="material-icons">cloud_download</mat-icon>
        </div>
        <div *ngIf="element.downloadState == 3" class="icon-primary-color">
          <mat-icon aria-label="Menu" class="material-icons">cloud_done</mat-icon>
        </div>
        <div *ngIf="element.downloadState == 1" class="icon-primary-color">
          <mat-icon aria-label="Menu" class="material-icons">cloud_queue</mat-icon>
        </div>
      </td>
    </ng-container>
    <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
    <tr mat-row *matRowDef="let row; columns: ['name' , 'downloadState'];"
      (click)="openFile(row.file, row.downloadState)" class="pointer-cursor"></tr>
  </table>

</div>

<ng-template #loading>
  <mat-spinner style="margin :auto ; margin-top : 50px"></mat-spinner>
</ng-template>
<h1 mat-dialog-title>Recherche</h1>
<div mat-dialog-content>
  <mat-form-field>
    <input matInput [(ngModel)]="data.inputFilter">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Annuler</button>
  <button mat-button [mat-dialog-close]="data.inputFilter" cdkFocusInitial>Ok</button>
</div>

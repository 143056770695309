import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { MatDialog } from '@angular/material/dialog';
import { updateServicePopUp } from './updateServicePopUp';
import { filter, map } from 'rxjs/operators';
import { tools } from './tools';


@Injectable()
export class updateService {
  constructor(private readonly updates: SwUpdate, private dialog: MatDialog, private tools: tools) {
    this.updates.available.subscribe(event => {
      this.showAppUpdateAlert();
    });
  }
  showAppUpdateAlert() {
    console.log("-- event reload  --")
    const dialogRef = this.dialog.open(updateServicePopUp);
    dialogRef.afterClosed().subscribe(result => {
      console.log('recharger ', result);
      if (result == true)
        this.doAppUpdate()
    });
  }
  doAppUpdate() {
    this.updates.activateUpdate().then(() => {
      document.location.reload()
    });
  }

  checkForUpdate() {
    this.tools.delay(3000).then(res => {
      this.updates.checkForUpdate()
    })
  }
}


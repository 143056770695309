<mat-sidenav-container fullscreen class="mainContainer">
  <mat-sidenav #sidenav mode="over" class="app-sidenav">
    <div class="menuToolbar">
      <mat-toolbar color="primary" class="matToolBar">
        <!--future svg to go here-->
        <span class="toolbar-filler">Menu</span>
        <button mat-icon-button (click)="sidenav.toggle()" class="mat-icon-button sidenav-toggle-button"
          [hidden]="!sidenav.opened">
          <mat-icon aria-label="Menu" class="material-icons" style="margin-left : 5px">close</mat-icon>
        </button>
      </mat-toolbar>
      <mat-nav-list>
        <div *ngFor="let item of listItems">
          <span *ngIf="item.icon != '' else noIconMenu">
            <mat-list-item class="sidenav-link" (click)="setPageName(item.title)" [routerLink]="[item.pageLink]">
              <mat-icon mat-list-icon>{{item.icon}}</mat-icon>
              <h4 mat-line>{{item.title}}</h4>
            </mat-list-item>
          </span>
          <ng-template #noIconMenu>
            <mat-list-item class="sidenav-link" (click)="setPageName(item.title)" [routerLink]="[item.pageLink]">
              <h4 mat-line>{{item.title}}</h4>
            </mat-list-item>
          </ng-template>
        </div>
        <!-- div deconnexion -->
        <div class="bottom-menu">
          <a mat-list-item class="sidenav-link" (click)="setPageName('Paramètres')" [routerLink]="['/configure-app']">
            <mat-icon mat-list-icon>account_circle</mat-icon>
            <h4 mat-line>Paramètres</h4>
          </a>
          <!-- <a mat-list-item class="sidenav-link" (click)="logout()">
              <mat-icon mat-list-icon>power_settings_new</mat-icon>
              <span class="title" mat-line>Déconnexion</span>
            </a> -->
        </div>
      </mat-nav-list>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="mat-sidenav-content-cust">
    <mat-toolbar class="appToolbar" color="primary">
      <button mat-icon-button (click)="topLeftButton()" class="mat-icon-button sidenav-toggle-button"
        [hidden]="sidenav.opened">
        <ng-container *ngIf="menuIcon === 'menu' ; else arrowBack">
          <img src="./assets/icons/menu-24px.svg" />
        </ng-container>
        <ng-template #arrowBack>
          <img src="./assets/icons/arrow_back-24px.svg" />
        </ng-template>
        <!-- <mat-icon aria-label="Menu" class="material-icons">{{menuIcon}}</mat-icon> -->
      </button>
      <h1 class="component-title">
        <a class="title-link">{{currentPage}}</a>
      </h1>
      <span class="toolbar-filler"></span>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
import { Injectable, OnDestroy } from '@angular/core';
import { StoreFileService } from '../drive-services/store-file.service'
import { Subscription, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ParseJsonFileService implements OnDestroy {


  //local event
  private subscriptionStringPartReaded: Subscription;
  private stringHeaderReaderSource = new Subject<string>();
  stringHeaderReader$ = this.stringHeaderReaderSource.asObservable();

  //event for caller
  private stringHeaderResultSource = new Subject<string>();
  stringHeaderResult$ = this.stringHeaderResultSource.asObservable();

  private stringAllResultSource = new Subject<string>();
  stringAllResult$ = this.stringAllResultSource.asObservable();


  private headerPartString: string = "";
  private startBlock: number = 0;
  private endBlock: number = 128;
  private uidFile: string;

  constructor(private storeFileService: StoreFileService) {
    this.subscriptionStringPartReaded = this.stringHeaderReader$.subscribe(
      value => {
        this.endBlocRead(value)
      }
    )
  }

  public setStringHeaderReader(strHeader: string) {
    this.stringHeaderReaderSource.next(strHeader);
  }

  public ngOnDestroy() {
    this.subscriptionStringPartReaded.unsubscribe();
  }

  public setStringHeaderResult(header : string){
    this.stringHeaderResultSource.next(header);
  }

  public setStringAllResult(all : string){
    this.stringAllResultSource.next(all);
  }


  public readSpecificFileHeader(uidFileName: string) {
    this.uidFile = uidFileName;
    this.storeFileService.getfileEntry(uidFileName)
    .pipe(
      first()).subscribe(
        fileEntry => {
        if (fileEntry != null) {
          fileEntry.file(function(fileBlob){
            var self = this;
            var reader = new FileReader();
            var firstPartOfBlob = fileBlob.slice(self.startBlock, self.endBlock);
            reader.onloadend = (evt :any) => {
              self.endBlocRead( evt.target.result ) 
            };
            reader.readAsText(firstPartOfBlob);            
          }.bind(this), function(evtError){ 
            console.log(evtError)
          })
        }
      })
  }

  public endBlocRead(valueReaded :string) {
    //Methode call when block is readed
    this.headerPartString = this.headerPartString + valueReaded;
    let index = this.headerPartString.indexOf('{')
    if(index != -1 ) {
      this.headerPartString = this.headerPartString.slice(0, index);
      this.setStringHeaderResult(this.headerPartString)
      //generate Event end of reading 
    }
    else {
      this.startBlock = this.endBlock + 1;
      this.endBlock = this.startBlock + 128;
      this.readSpecificFileHeader(this.uidFile);
    }
  }

  public readSpecificFile(uidFileName : string)
  {
    this.uidFile = uidFileName;
    this.storeFileService.getfileEntry(uidFileName)
    .pipe(
      first()).subscribe(
        fileEntry => {
          this.setStringAllResult(fileEntry)
        })


        // if (fileEntry != null) {
        //   fileEntry.file(function(fileBlob){
        //     var self = this;
        //     var reader = new FileReader();
        //     reader.onloadend = (evt :any) => {
        //       self.setStringAllResult(evt.target.result) 
        //     };
        //     reader.readAsText(fileBlob);            
        //   }.bind(this), function(evtError){ 
        //     console.log(evtError)
        //   })
        // }
      // })
  }
}



<!-- <ng-container *ngIf="jsonReaded!= null && convertionFileContent != null">
  <div *ngFor="let componentConvert of convertionFileContent">
    <div [ngSwitch]="findController(componentConvert.itemLine)">
      <div *ngSwitchCase="'deployer'" class="">
        <ng-container *ngIf="componentConvert.valuesParsed.controlOption == 'vrai'; else notDeploy">
          <mat-accordion>
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                {{componentConvert.valuesParsed.libelle}}
              </mat-expansion-panel-header>
              <ng-container *ngIf="1 == 1; else singleSubElem">
                <ng-container *ngFor="let elemJson of jsonReaded[componentConvert.itemLine] ; let i = index">
                  <ng-container *ngFor="let elemSubConversion of getSubConversion(componentConvert.itemLine)">
                    <ng-container
                      *ngIf="elemJson[elemSubConversion.itemLine] != null && elemJson[elemSubConversion.itemLine] != '' "
                      [ngTemplateOutlet]="singleItem"
                      [ngTemplateOutletContext]="{componentConvert:elemSubConversion, jsonToDisplay : elemJson[elemSubConversion.itemLine]}">
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
        <ng-template #notDeploy>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                {{componentConvert.valuesParsed.libelle}}
              </mat-expansion-panel-header>
              <ng-container *ngIf="1 == 1; else singleSubElem">
                <ng-container *ngFor="let elemJson of jsonReaded[componentConvert.itemLine] ; let i = index">
                  <ng-container *ngFor="let elemSubConversion of getSubConversion(componentConvert.itemLine)">
                    <ng-container
                      *ngIf="elemJson[elemSubConversion.itemLine] != null && elemJson[elemSubConversion.itemLine] != '' "
                      [ngTemplateOutlet]="singleItem"
                      [ngTemplateOutletContext]="{componentConvert:elemSubConversion, jsonToDisplay : elemJson[elemSubConversion.itemLine]}">
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-template>
      </div>
      <div *ngSwitchDefault class="default">

        <ng-container
          *ngIf="jsonReaded[componentConvert.itemLine] != null && jsonReaded[componentConvert.itemLine] != '' "
          [ngTemplateOutlet]="singleItem"
          [ngTemplateOutletContext]="{componentConvert:componentConvert, jsonToDisplay : jsonReaded[componentConvert.itemLine]}">
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #singleItem let-componentConvert="componentConvert" let-jsonToDisplay="jsonToDisplay">
  <div [ngSwitch]="findController(componentConvert.itemLine)">
    <div *ngSwitchCase="'telephone'" class="tel">
      <span
        *ngIf="componentConvert.valuesParsed.libelle != null && componentConvert.valuesParsed.libelle != '' ; else useKeyAsLabel"
        class="">
        {{componentConvert.valuesParsed.libelle}}
      </span>
      <ng-template #useKayAsLabel class="">{{componentConvert.itemLine}}</ng-template>
      <span class=""><a href="tel:{{jsonToDisplay}}"> :
          {{jsonToDisplay}}</a>
      </span>
    </div>
    <div *ngSwitchCase="'mail'" class="mail">
      <span
        *ngIf="componentConvert.valuesParsed.libelle != null && componentConvert.valuesParsed.libelle != '' ; else useKeyAsLabel"
        class="">
        {{componentConvert.valuesParsed.libelle}}
      </span>
      <ng-template #useKayAsLabel class="">{{componentConvert.itemLine}}</ng-template>
      <span class=""><a href="mailto:{{jsonToDisplay}}"> :
          {{jsonToDisplay}}</a>
      </span>
    </div>
    <div *ngSwitchCase="'dateheure'" class="date">
      <span
        *ngIf="componentConvert.valuesParsed.libelle != null && componentConvert.valuesParsed.libelle != '' ; else useKeyAsLabel"
        class="">
        {{componentConvert.valuesParsed.libelle}}
      </span>
      <ng-template #useKayAsLabel class="">{{componentConvert.itemLine}}</ng-template>
      <span class=""> : {{jsonToDisplay | date:'dd/LL/yyyy, HH:mm'}} </span>
    </div>
    <div *ngSwitchCase="'date'" class="date">
      <span
        *ngIf="componentConvert.valuesParsed.libelle != null && componentConvert.valuesParsed.libelle != '' ; else useKeyAsLabel"
        class="">
        {{componentConvert.valuesParsed.libelle}}
      </span>
      <ng-template #useKayAsLabel class="">{{componentConvert.itemLine}}</ng-template>
      <span class=""> : {{jsonToDisplay | date:'dd/LL/yyyy'}} </span>
    </div>
    <div *ngSwitchDefault class="default">
      <span
        *ngIf="componentConvert.valuesParsed.libelle != null && componentConvert.valuesParsed.libelle != '' ; else useKeyAsLabel"
        class="">
        {{componentConvert.valuesParsed.libelle}}
      </span>
      <ng-template #useKayAsLabel class="">{{componentConvert.itemLine}}</ng-template>
      <span class=""> : {{jsonToDisplay}}</span>
    </div>
  </div>

</ng-template> -->

<ng-container *ngFor="let control of displayedControls">
  <ng-container *ngIf="control.controlType != 'deployer'" [ngTemplateOutlet]="singleItem"
    [ngTemplateOutletContext]="{controllerType:control.controlType, description : control.description , value : control.value, style : control.style}">
  </ng-container>
  <ng-container *ngIf="control.controlType == 'deployer'">
    <ng-container *ngIf="control.controlOption == 'vrai'; else notDeploy">
      <mat-accordion (click)="forceDetectChange()">
        <mat-expansion-panel expanded class="padding-expension-pannel">
          <mat-expansion-panel-header class="expension-title">
            {{control.description.libelle}}
          </mat-expansion-panel-header>
          <ng-container *ngFor="let subControl of control.subContent">
            <mat-divider *ngIf="subControl.seperator == true" class="margin-divider-15"></mat-divider>
            <ng-container [ngTemplateOutlet]="singleItem"
              [ngTemplateOutletContext]="{controllerType:subControl.controlType, description : subControl.description , value : subControl.value, style : subControl.style}">
            </ng-container>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
    <ng-template #notDeploy>
      <mat-accordion (click)="forceDetectChange()">
        <mat-expansion-panel class="padding-expension-pannel">
          <mat-expansion-panel-header class="expension-title">
            {{control.description.libelle}}
          </mat-expansion-panel-header>
          <ng-container *ngFor="let subControl of control.subContent">
            <mat-divider *ngIf="subControl.seperator == true" class="margin-divider-15"></mat-divider>
            <ng-container [ngTemplateOutlet]="singleItem"
              [ngTemplateOutletContext]="{controllerType:subControl.controlType, description : subControl.description , value : subControl.value, style : subControl.style}">
            </ng-container>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-template>
  </ng-container>
</ng-container>



<ng-template #singleItem let-controllerType="controllerType" let-description="description" let-value="value"
  let-style="style">
  <div [ngSwitch]="controllerType" class="single-line">
    <div *ngSwitchCase="'telephone'" class="tel" [ngStyle]="style">
      <span *ngIf="description != null && description.libelle != null && description.libelle != '' "
        class="single-line-italic">
        {{description.libelle}} :
      </span>
      <span class=""><a href="tel:{{value}}">
          {{value}}</a>
      </span>
    </div>
    <div *ngSwitchCase="'telephone_prive'" class="tel" [ngStyle]="style">
      <span *ngIf="description != null && description.libelle != null && description.libelle != '' "
        class="single-line-italic">
        {{description.libelle}} :
      </span>
      <span class=""><a href="tel:{{'%2331%23'+value}}">
          {{'#31#'+value}}</a>
      </span>
    </div>
    <div *ngSwitchCase="'mail'" class="mail" [ngStyle]="style">
      <span *ngIf="description != null && description.libelle != null && description.libelle != '' "
        class="single-line-italic">
        {{description.libelle}} :
      </span>
      <span class=""><a href="mailto:{{value}}">
          {{value}}</a>
      </span>
    </div>
    <div *ngSwitchCase="'dateheure'" class="date" [ngStyle]="style">
      <span *ngIf="description != null && description.libelle != null && description.libelle != ''"
        class="single-line-italic">
        {{description.libelle}} :
      </span>
      <span class=""> {{value | date:'dd/LL/yyyy HH:mm'}} </span>
    </div>
    <div *ngSwitchCase="'date'" class="date" [ngStyle]="style">
      <span *ngIf="description != null && description.libelle != null && description.libelle != ''"
        class="single-line-italic">
        {{description.libelle}} :
      </span>
      <ng-template #useKayAsLabel class="single-line-italic">{{controllerType}} :</ng-template>
      <span class=""> {{value | date:'dd/LL/yyyy'}} </span>
    </div>
    <div *ngSwitchCase="'gps_itin'" class="date" [ngStyle]="style">
      <span *ngIf="description != null && description.libelle != null && description.libelle != '' "
        class="single-line-italic">
        {{description.libelle}} :
      </span>
      <span class="">
        <a href="https://www.google.com/maps/dir/?api=1&destination={{value}}">Itinéraire
          <mat-icon aria-label="Nav" class="material-icons" style="vertical-align : bottom !important">navigation
          </mat-icon>
        </a>
      </span>
    </div>
    <div *ngSwitchCase="'gps_loca'" class="date" [ngStyle]="style">
      <span *ngIf="description != null && description.libelle != null && description.libelle != '' "
        class="single-line-italic">
        {{description.libelle}} :
      </span>
      <span class="">
        <a href="https://www.google.com/maps/search/?api=1&query={{value}}">Localisation
          <mat-icon aria-label="Nav" class="material-icons" style="vertical-align : bottom !important">place</mat-icon>
        </a>
      </span>
    </div>
    <div *ngSwitchCase="'url'" class="date" [ngStyle]="style">
      <ng-container *ngIf="value != null && value != '' && value.includes('http')">
        <span *ngIf="description != null && description.libelle != null && description.libelle != '' ; "
          class="single-line-italic">
          {{description.libelle}} :
        </span>
        <ng-template #useKayAsLabel class="single-line-italic">{{controllerType}}</ng-template>
        <span class=""><a href="{{value}}" target="_blanck">{{value}}</a> </span>
      </ng-container>
    </div>
    <div *ngSwitchDefault class="default" [ngStyle]="style">
      <span *ngIf="description != null && description.libelle != null && description.libelle != '' "
        class="single-line-italic">
        {{description.libelle}} :
      </span>
      <span class=""> {{value}}</span>
    </div>
  </div>

</ng-template>
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SafeUrl } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class SharedMenuService {

  // Observable  sources
  private companyListSource = new Subject<companyDetails[]>();
  private loginStateSource = new Subject<boolean>();
  private menuItemsSource = new Subject<menuAppItem[]>()
  private currentUserSource = new Subject<currentUser>()
  private notificationListSource = new Subject<notification[]>();
  private companyIndiceSource = new Subject<string>();
  private appTitleSource = new Subject<string>();
  private menuIconSource = new Subject<string>();
  private loadingStateSource = new Subject<boolean>();
  private snackBarIsCloseSource = new Subject<boolean>();

  // Observable  streams
  companyList$ = this.companyListSource.asObservable();
  loginState$ = this.loginStateSource.asObservable();
  menuItems$ = this.menuItemsSource.asObservable();
  currentUser$ = this.currentUserSource.asObservable();
  companyIndice$ = this.companyIndiceSource.asObservable();
  loadingState$ = this.loadingStateSource.asObservable();
  notificationList$ = this.notificationListSource.asObservable();
  appTitle$ = this.appTitleSource.asObservable();
  menuIcon$ = this.menuIconSource.asObservable();
  snackBarIsClose$ = this.snackBarIsCloseSource.asObservable();


  public setLogin(loginState: boolean) {
    this.loginStateSource.next(loginState);
  }
  public setcompanyList(companyList: companyDetails[]) {
    this.companyListSource.next(companyList);
  }
  public setMenuItems(menuAppItem: menuAppItem[]) {
    this.menuItemsSource.next(menuAppItem);
  }
  public setCurrentUser(currentUser: currentUser) {
    this.currentUserSource.next(currentUser);
  }
  public setcompanyIndice(companyIndice: string) {
    this.companyIndiceSource.next(companyIndice);
  }
  public setLoadingState(loadingState: boolean) {
    this.loadingStateSource.next(loadingState);
  }
  public setsnackBarIsClose(snackBarIsClose: boolean) {
    this.snackBarIsCloseSource.next(snackBarIsClose);
  }  
  public setNotification(notificationList: notification[]) {
    this.notificationListSource.next(notificationList);
  }  
  public setAppTitle(appTitle: string) {
    this.appTitleSource.next(appTitle);
  }
  public setMenuIcon(icon : string){
    this.menuIconSource.next(icon)
  }




}

export interface menuAppItem {
  icon: string;
  title: string; 
  divider: boolean;
  pageLink: string;
}

export interface companyDetails {
  name : string;
  logo : string; //svgIcon base64 img
  companyGuid : string;
  applications : application[];
}

export interface application {
  displayName : string;
  apiName : string;
  description : string;
  publicationDate : Date;
  active : boolean;
  apiUrl : string;
  svgIcon : SafeUrl; 
}

export interface notification {
  dateNotification : Date;
  message : string;
  urlRedirection : string;
  informationLevel : number;
}

export interface currentUser {
  displayName : string;
  firstName : string;
  lastName : string;
  userName : string
}

export interface currentCompany {
  idCompany : string;
}


import { Component, OnInit, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { InitialiseAppService } from '../initialise-app.service'
import { SharedMenuService, currentUser, menuAppItem, companyDetails } from '../services/shared-menu.service'
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {


  @ViewChild('sidenav') public sidenav: any;

  public companyList: companyDetails[] = [];
  public selectedCompany: string;
  public listItems: menuAppItem[] = [];
  public currentUser: currentUser;
  public loading: boolean = true;
  public currentSocietyShortName: string = "";
  public societySelected: companyDetails;
  // public companySelector: FormGroup;
  public currentPage: string;
  public menuIcon: string = "menu";

  private subscriptionCompanyIndice: Subscription;
  private subscriptioncompanyList: Subscription;
  private subscriptionCurrentUser: Subscription;
  private subscriptionMenuItems: Subscription;
  private subscriptionLoadingState: Subscription;
  private subscriptionLoginState: Subscription;
  private subscriptionCurrentPage : Subscription;
  private subscriptionMenuIcon : Subscription;

  constructor(private sharedMenuService: SharedMenuService,
    private router: Router,
    private zone: NgZone,
    private bottomSheet: MatBottomSheet,
    private initialiseAppService: InitialiseAppService) {

    this.subscriptionCompanyIndice = sharedMenuService.companyIndice$.subscribe(
      value => {
        this.selectedCompany = value;
        if (this.companyList != null && this.companyList.length > 0 && this.selectedCompany != null) {
          this.setCompanyShortName()
        }
      }
    )
    this.subscriptioncompanyList = sharedMenuService.companyList$.subscribe(
      value => {
        this.companyList = value;
        listCompanyshared = this.companyList
        if (this.companyList != null && this.companyList.length > 0 && this.selectedCompany != null)
          this.setCompanyShortName()
      }
    )
    this.subscriptionCurrentUser = sharedMenuService.currentUser$.subscribe(
      value => {
        this.currentUser = value;
      }
    )
    this.subscriptionMenuItems = sharedMenuService.menuItems$.subscribe(
      value => {
        this.listItems = value;
      }
    )
    this.subscriptionLoadingState = sharedMenuService.loadingState$.subscribe(
      value => {
        this.loading = value;
      }
    )

    this.subscriptionCurrentPage = sharedMenuService.appTitle$.subscribe(
      value => {
        this.currentPage = value;
      }
    )

    this.subscriptionMenuIcon = sharedMenuService.menuIcon$.subscribe(
      value => {
        this.menuIcon = value;
      }
    )
  }

  ngOnInit() {
    this.initialiseAppService.loadConfig()
    this.sharedMenuService.setLoadingState(true);
  }

  ngOnDestroy() {
    this.subscriptionCompanyIndice.unsubscribe();
    this.subscriptioncompanyList.unsubscribe();
    this.subscriptionCurrentUser.unsubscribe();
    this.subscriptionMenuItems.unsubscribe();
    this.subscriptionLoadingState.unsubscribe();
    this.subscriptionLoginState.unsubscribe();
    this.subscriptionCurrentPage.unsubscribe();
    this.subscriptionMenuIcon.unsubscribe();
  }

  public setPageName(pageSelected: string) {
    this.currentPage = pageSelected;
    this.sidenav.toggle()
  }

  public setCompanyShortName() {
    this.societySelected = this.companyList.find(cmpItem => cmpItem.companyGuid == this.selectedCompany);

    // if (this.companySelector.get('company').valsue == null)
    //   this.companySelector.get('company').setValue(this.societySelected);
    if (this.societySelected != null) {
      let words = this.societySelected.name.split(' ')
      if (words.length > 1) {
        this.currentSocietyShortName = words[0].split('')[0].toUpperCase() + words[words.length - 1].split('')[0].toLowerCase()
        return
      }
      words = this.societySelected.name.split('-')
      if (words.length > 1) {
        this.currentSocietyShortName = words[0].split('')[0].toUpperCase() + words[words.length - 1].split('')[0].toLowerCase()
        return
      }
      words = this.societySelected.name.split('');
      this.currentSocietyShortName = words[0].toUpperCase() + words[1].toLowerCase();
    }
  }

  public clearObject() {
    this.currentSocietyShortName = "";
    this.companyList = []
  }

  public openBottomSheet(): void {
    if (this.companyList.length > 1)
      this.bottomSheet.open(bottomList);
  }

  public topLeftButton()
  {
    if(this.menuIcon != "arrow_back_ios")
      this.sidenav.toggle()
    else
      history.back()
  }
}

let listCompanyshared: any

@Component({
  selector: 'bottom-list',
  templateUrl: 'bottomlistTemplate.html'
})
export class bottomList {


  constructor(private bottomSheetRef: MatBottomSheetRef<bottomList>,
    private sharedMenuService: SharedMenuService,
    private router: Router
  ) { }

  public societyList: any = listCompanyshared;

  openLink(idSelected: any): void {
    this.sharedMenuService.setcompanyIndice(idSelected);
    //write currentSubscriberID
    localStorage.setItem("historyProfil", JSON.stringify(idSelected))
    localStorage.setItem("currentSubscriberID", JSON.stringify(idSelected))
    this.bottomSheetRef.dismiss();
    //router reload
  }

}

import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

import { timeout, map, catchError, filter, takeUntil } from 'rxjs/operators';
import { HttpRequestService } from '../../drive-services/http-request.service';
import { HttpHeaders } from '@angular/common/http';
import { msalConfiguration } from 'src/environments/environment';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { SharedMenuService } from 'src/app/services/shared-menu.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class VerifyAccessService implements OnDestroy {


  public isLoading: boolean = true;
  public isAccessToOneDriveValid: boolean = false;
  public userName: string;
  private subscription: Subscription;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private httpRequestService: HttpRequestService,
    private msalService: MsalService,
    private router: Router,
    private sharedMenuService: SharedMenuService,
    private maslBroadcastService: MsalBroadcastService
  ) {
    this.intialiseMsalObserver()
  }

  public verifyAcces(): Observable<any> {

    let accounts = this.msalService.instance.getAllAccounts();
    this.msalService.instance.setActiveAccount(accounts[0]);
    this.isAccessToOneDriveValid = true;

    let item: any
    item = localStorage.getItem("msal.state.login")
    if (accounts == null || accounts.length == 0) {
      return new Observable((observer) => {
        observer.next(false)
        observer.complete()
      });
    }

    else {
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      return this.httpRequestService.getData(msalConfiguration.rootAppFolderUrl, httpOptions)
        .pipe(
          timeout(8000),
          map(result => {
            if (result.name.includes(this.httpRequestService.appName))
              return true
            else if (result.name.includes("HttpErrorResponse"))
              return null
            else
              return false
          }),
          catchError(e => {
            return new Observable((observer) => {
              observer.next(false)
              observer.complete()
            });
          })
        )
    }
  }

  private intialiseMsalObserver() {
    this.maslBroadcastService.msalSubject$.pipe().subscribe((result: EventMessage) => {
      // do something here
      if (result.eventType == EventType.LOGIN_SUCCESS) {
        console.log("msal:loginSuccess", result.payload);
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account)
        // this.msalService.acquireTokenSilent()
      }
      else if (result.eventType == EventType.ACQUIRE_TOKEN_SUCCESS) {
        const payload = result.payload as AuthenticationResult;
        localStorage.setItem("_token", payload.accessToken);

        let base64Url = payload.accessToken.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        localStorage.setItem("userName", JSON.parse(jsonPayload).unique_name);
        this.userName = JSON.parse(jsonPayload).unique_name;
        this.isAccessToOneDriveValid = true;
        this.sharedMenuService.setLoadingState(false);
        // this.msalService.acquireTokenSilent()
      }

      this.sharedMenuService.setLoadingState(true);
      this.isAccessToOneDriveValid = false;
    });

    this.maslBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      })

  }

  public setLoginDisplay() {
    console.log("acount counter : ", this.msalService.instance.getAllAccounts().length > 0)
  }

  public checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnDestroy() {
    // this.broadcastService.getMSALSubject().unsubscribe()
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    // this.broadcastService.
  }
}

export const environment = {
  production: true,
  versionCheckURLDefault: window.location.origin

};

export const protectedResourceMap: [string, string[]][] = [['https://graph.microsoft.com/v1.0/me/drive', ["Files.Read", "Files.Read.All", "Files.Read.Selected", "Files.ReadWrite", "Files.ReadWrite.All", "Files.ReadWrite.AppFolder", "Files.ReadWrite.Selected", "user.read", "offline_access"]],
];

export const msalConfiguration =
{
  clientID: "d7efa2c3-e84b-4de0-bc4b-48a03dfa8447",
  consentScopes: ["Files.Read", "Files.Read.All", "Files.Read.Selected", "Files.ReadWrite", "Files.ReadWrite.All", "Files.ReadWrite.AppFolder", "Files.ReadWrite.Selected", "user.read", "offline_access"],
  piiLoggingEnabled: true,  
  redirectUri: "https://mesfichiers.obexto.fr/configure-app",
  protectedResourceMap: protectedResourceMap,
  validateAuthority: false,
  cacheLocation: "localStorage",
  storeAuthStateInCookie: false,
  popUp: true,
  rootAppFolderUrl : "https://graph.microsoft.com/v1.0/me/drive/special/approot",
  deltaAppFolderUrl : "https://graph.microsoft.com/v1.0/me/drive/root/delta"
}

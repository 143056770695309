import { Injectable } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {
  SharedMenuService,
  application,
  companyDetails,
  currentCompany,
  currentUser,
  menuAppItem,
  notification
} from './services/shared-menu.service'
import { GetJsonObjectService } from './services/get-json-object.service';
import { DomSanitizer } from '@angular/platform-browser';

import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class InitialiseAppService {

  listItems: menuAppItem[] = [
    { icon: 'home', title: 'Mes Fichiers', divider: false, pageLink: 'home' },
  ];

  constructor(private sharedMenuService: SharedMenuService,
    private getJsonObjectService: GetJsonObjectService,
    private sanitizer: DomSanitizer) {
  }

  public loadConfig(){
    this.sharedMenuService.setMenuItems(this.listItems);
    this.sharedMenuService.setAppTitle("Mes fichier entreprise");
  }


}

// export interface menuAppItem {
//   // use fake backend in place of Http service for backend-less development
//   icon: string;
//   title: string;
//   divider: boolean;
//   pageLink: string;
// };

<!-- 
2 OPTION  :
  le paramètre onedrive token existe => changer le compte one drive
  Le paramètre n'existe pas => configurer le compte one drive

//rootbasePath fixed using app
  -->
<div *ngIf="!isLoading " class="main-container">
  <div *ngIf="!isAccessToOneDriveValid ; else accesValid" class="font-size-13">
    Accès à votre OneDrive impossible <br /><br />
    <button mat-raised-button color="primary" (click)="configureOneDrive()" class="font-size-12-margin-35">Configurer
      votre <br />accès OneDrive
    </button>
  </div>

  <ng-template #accesValid>
    <p style="font-size : 1.3em">Compte oneDrive actuellement configuré : <br /> {{userName}}</p>
    <div>
      <button mat-raised-button color="primary" *ngIf="isAccessToOneDriveValid" (click)="disconnectOneDrive()"
        class="font-size-12-margin-35">Modifier votre <br />accès OneDrive</button>
    </div>
    <div>
      <button mat-raised-button color="primary" *ngIf="isAccessToOneDriveValid"  routerLink="/home"
        class="font-size-12-margin-35">Afficher vos fichiers</button>
    </div>
  </ng-template>
</div>
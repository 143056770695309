import { Injectable, inject } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { Subject, Observable } from 'rxjs';
import { SharedMenuService } from './shared-menu.service';


@Injectable({
  providedIn: 'root'
})
export class tools {

  constructor() { }

  public compareArray(obj1: any[], obj2: any[]): boolean {
    if (obj1.length != obj2.length)
      return false
    for (let i = 0; i < obj1.length; i++) {
      if (obj1[i] != obj2[i])
        return false;
    }
    return true;
  }

  public guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  public TransformeJSONDate(obj: any): any {
    // let obj =  JSON.parse(stringObject,this.jsonDateParser); 
    for (let property in obj) {
      obj[property] = this.DateParser(obj[property]);
    }
    return obj;
  }

  private DateParser(value: string): any {
    const dateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
    if (typeof value === "string" && dateFormat.test(value)) {
      return new Date(value);
    }
    return value;
  }

  public delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}

@Component({
  selector: 'error-Snack-Bar',
  template: `<span class="snackBar">
                  <span class="marginIcon"><mat-icon>warning</mat-icon></span><span style="overflow : hidden">{{data}}</span>
                </span>`,
  styles: [`
      .snackBar {      
        color: white;
        font-size : 1.3em;
        text-align:center;
        font-style : italic;
        vertical-align : middle;
        display: inline-flex;
        color : #f44336;
        float : bottom;
        overflow : hidden;
        width : 90%;
      }
      .marginIcon{
        margin-right : 20px;
        display: inline-block;
      }
    `],
})

@Injectable({
  providedIn: 'root'
})
export class errorSnackBar {

  constructor(
    private sncackBarRef : MatSnackBarRef<errorSnackBar>,
    private sharedMenuService : SharedMenuService,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,  
  ) 
  {
    this.sharedMenuService.setsnackBarIsClose(false);
    this.sncackBarRef.afterDismissed().subscribe(() => 
    {
      this.sharedMenuService.setsnackBarIsClose(true);
    })
  }
}

@Component({
  selector: 'error-Snack-Bar',
  template: `<span class="snackBar">
                  <span class="marginIcon"><mat-icon>warning</mat-icon></span><span style="overflow : hidden">{{data}}</span>
                </span>`,
  styles: [`
      .snackBar {      
        color: white;
        font-size : 1.3em;
        text-align:center;
        font-style : italic;
        vertical-align : middle;
        display: inline-flex;
        color : #F7F7F7;
        float : bottom;
        overflow : hidden;
        width : 90%;
      }
      .marginIcon{
        margin-right : 20px;
        display: inline-block;
      }
    `],
})

export class succesSnackBar {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    console.log(data);
  }
}


export interface user {
  login: string,
  password: string
}
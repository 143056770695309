import { Injectable, } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { map, timeout, catchError, retry } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {

  public appName = "OneDriveMobile"

  private url: string = "";

  constructor(
    private http: HttpClient,
  ) { }

  public dataOutput;

  public getData(url: string, option?: any): Observable<any> {

    if (url != null && url != "") {
      const urlConst = url;
      return this.http.get(urlConst, option)
        .pipe(
          timeout(8000),
          map(data => {
            this.dataOutput = data;
            return data;
          }),
          catchError(e => {
            console.log("error when getting data", e);
            return new Observable((observer) => {
              this.dataOutput = e
              observer.next(e)
              observer.complete()
            });
          })
        );

    }
    else
      return;
  }

  public getJsonValue(): any {
    return this.dataOutput;
  }

  public postData(url: string, body: any, option?: any): Observable<any> {
    if (url != null) {
      return this.http.post(url, body, option)
        .pipe(
          timeout(9000),
          map(data => {
            this.dataOutput = data;
            return data;
          }),
          // catchError(this.handleError('getHeroes' ))
          catchError(e => {
            console.log(e.message);
            console.log(e.msg);
            return new Observable((observer) => {
              observer.next(e)
              observer.complete()
            })
          })
        );

    }
    else
      return;
  }

  private handleError() {
    console.log(this.dataOutput)
    return of(this.dataOutput);
  }
}


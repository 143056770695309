import { Component, Input, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import { Router } from '@angular/router';

import { map, filter, scan } from 'rxjs/operators';
import { timer, Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent } from 'rxjs';


@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements AfterViewInit {


  @ViewChild('sidenav') public myNav: any;
  @ViewChild("firstDigit") public firstDigit : any;
  @ViewChild("secondDigit") public secondDigit: any;
  @ViewChild("thirdDigit") public thirdDigit : any;
  cpt : number = 0;
  subscribe :any;

  constructor( private router: Router ) { }

  ngAfterViewInit(){
    const source = timer(0 , 27);
    this.subscribe = source.subscribe(val => {
      this.randomNumDisplay()
    });
    
  }

  private randomNum():number
  {      
      return Math.floor(Math.random() * 9)+1;
  }

  private randomNumDisplay(){        
        if(this.cpt > 30)
          this.thirdDigit.nativeElement.textContent = 4;
        else
          this.thirdDigit.nativeElement.textContent = this.randomNum();
        if(this.cpt > 50)
          this.secondDigit.nativeElement.textContent = 0;
        else
          this.secondDigit.nativeElement.textContent = this.randomNum();
        if(this.cpt > 70)
          this.firstDigit.nativeElement.textContent = 4;
        else
          this.firstDigit.nativeElement.textContent = this.randomNum();
        this.cpt++;
        if(this.cpt > 300)
          this.subscribe.unsubscribe();
  }

  public logout(){
    console.log("logout methode")

  }

  public redirectToGenerateMenu()
  {
    this.router.navigate(['']);          
  }   


}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { DisplayFilesComponent } from './display-files/display-files.component';
import { HelpComponent } from './help/help.component';

import { AlertComponent } from './alert/alert.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { GuardsService } from './services/guards.service';
import { ConfigureAppComponent } from './configure-app/configure-app.component'
import { DisplayJsonWebPageComponent } from './display-json-web-page/display-json-web-page.component';




const routes: Routes = [
  { path: 'app-not-found', component: NotFoundComponent},
  { path: 'display-json-web-page/:fileId', component: DisplayJsonWebPageComponent}, 
  { path: 'display-json-web-page', component: DisplayJsonWebPageComponent}, 
  { path: 'home', component: DisplayFilesComponent},
  { path: 'home/:pathId', component: DisplayFilesComponent},
  { path: 'configure-app', component : ConfigureAppComponent},
  { path: 'help', component: HelpComponent},
  { path: '', redirectTo: 'home', pathMatch : 'full'},
  { path: '**',  redirectTo: ''},
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'ignore', relativeLinkResolution: 'legacy' }) ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

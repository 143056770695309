import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewChecked, ElementRef, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ParseJsonFileService } from './parse-json-file.service'
import { SharedMenuService } from '../services/shared-menu.service';


@Component({
  selector: 'app-display-json-web-page',
  templateUrl: './display-json-web-page.component.html',
  styleUrls: ['./display-json-web-page.component.scss']
})
export class DisplayJsonWebPageComponent implements OnInit, OnDestroy, AfterViewChecked {


  private fileId: string = ""
  public convertionFileContent: any[] = [];
  public displayedControls: any[] = [];

  private paramSubscription: Subscription;
  public jsonReaded: any;
  public refresh: boolean = false

  private subscriptionFileReader: Subscription;


  constructor(
    private route: ActivatedRoute,
    private parseJsonFile: ParseJsonFileService,
    private sharedMenuService: SharedMenuService,
    private cd: ChangeDetectorRef,) 
    {
    this.subscriptionFileReader = parseJsonFile.stringAllResult$.subscribe(
      value => {
        this.parsejsonReaded(value);
        this.generateObjectForHtml();
      }
    )
  }

  ngOnInit() {
    this.paramSubscription = this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.fileId = params['fileId'] || "";
      let listOfConvertion: any[] = params['convertionObject'];
      for (let i = 0; i < listOfConvertion.length; i++) {
        this.convertionFileContent.push(JSON.parse(listOfConvertion[i]))
      }
      this.fileReaderResult(this.fileId);
      this.sharedMenuService.setMenuIcon("arrow_back_ios");      
    });
  }

  ngOnDestroy() {
    this.paramSubscription.unsubscribe();
    this.subscriptionFileReader.unsubscribe();
  }

  private fileReaderResult(fileReaded: string) {
    this.parseJsonFile.readSpecificFile(fileReaded)
  }

  ngAfterViewChecked() {
  }

  private parsejsonReaded(rawFile: string) {
    this.jsonReaded = JSON.parse(rawFile.slice(rawFile.indexOf('{')));
  }

  public findController(key: any): any {
    for (let i = 0; i < this.convertionFileContent.length; i++) {
      if (this.convertionFileContent[i].itemLine.includes(key))
        return this.convertionFileContent[i].valuesParsed.typeControl
    }
    return null
  }


  public getSubConversion(itemParentName: string): any[] {
    //generate subConversionObject
    let subConvObj = []
    for (let i = 0; i < this.convertionFileContent.length; i++) {
      if (this.convertionFileContent[i].itemLine.includes(itemParentName + '\\')) {
        subConvObj.push({
          "itemLine": this.convertionFileContent[i].itemLine.slice(this.convertionFileContent[i].itemLine.indexOf('\\') + 1),
          "valuesParsed": this.convertionFileContent[i].valuesParsed
        })
      }
    }
    return subConvObj
  }

  private generateObjectForHtml() {
    this.displayedControls = []
    for (let i = 0; i < this.convertionFileContent.length; i++) {

      if (this.jsonReaded[this.convertionFileContent[i].itemLine] != null && this.jsonReaded[this.convertionFileContent[i].itemLine] != "") {
        if (this.convertionFileContent[i].valuesParsed.typeControl != "deployer"
          && !this.convertionFileContent[i].itemLine.includes("\\")
          && this.jsonReaded[this.convertionFileContent[i].itemLine] != null
          && this.jsonReaded[this.convertionFileContent[i].itemLine] != ""
        ) {
          let style = ""
          if (this.convertionFileContent[i].valuesParsed != null && this.convertionFileContent[i].valuesParsed.style != null)
          {
            try {
              style = JSON.parse(this.convertionFileContent[i].valuesParsed.style)
            }
            catch {
              style= "";
            }
          }
          this.displayedControls.push(
            {
              "controlType": this.convertionFileContent[i].valuesParsed.typeControl,
              "description": this.convertionFileContent[i].valuesParsed,
              "value": this.jsonReaded[this.convertionFileContent[i].itemLine],
              "style": style
            })
        }
        else  //control Deployer
        {
          let subItem: any[] = []
          let subJson = this.jsonReaded[this.convertionFileContent[i].itemLine]
          for (let j = 0; j < subJson.length; j++) {
            //generate sub convertionFileContent
            let subConversion: any[] = this.getSubConversion(this.convertionFileContent[i].itemLine)
            for (let k = 0; k < subConversion.length; k++) {
              if (subJson[j] != null && subJson[j] != "") {
                if ((subConversion[k].valuesParsed.typeControl != "deployer") &&
                  (subJson[j][subConversion[k].itemLine] != null) &&
                  (subJson[j][subConversion[k].itemLine] != "")) {
                  let style = ""
                  let separator = false;
                  if(k == 0 && j != 0 && subJson.length > 1)
                    separator = true
                  if (subConversion[k].valuesParsed != null && subConversion[k].valuesParsed.style != null)
                    style = JSON.parse(subConversion[k].valuesParsed.style)
                  subItem.push(
                    {
                      "controlType": subConversion[k].valuesParsed.typeControl,
                      "description": subConversion[k].valuesParsed,
                      "value": subJson[j][subConversion[k].itemLine],
                      "style": style,
                      "seperator" : separator
                    })
                }
              }
            }
          }
          this.displayedControls.push({
            "controlType": "deployer",
            "description": this.convertionFileContent[i].valuesParsed,
            "controlOption": this.convertionFileContent[i].valuesParsed.controlOption,
            "subContent": subItem
          })
        }
      }
    }
    // this.appRef.tick();
    this.cd.detectChanges();
  }

  public forceDetectChange() {
    this.cd.detectChanges();
  }

  public jsonToArray(itemParentName: string) {
    let jsonArray = []
    jsonArray.push(itemParentName);
    return jsonArray
  }

  public generateSubJson(key: string): any {
    let indexSlash = key.indexOf('\\')
    if (indexSlash > 0)
      key = key.slice(indexSlash + 1);
    let subJson: string = "{\"" + key + "\":\"" + this.jsonReaded[key] + "\"}";
    return JSON.parse(subJson);
  }
}

<div class="example-container">
  <mat-toolbar id="appToolbar" color="primary"  class="example-toolbar">
    <button mat-icon-button (click)="sidenav.toggle()" class="mat-icon-button sidenav-toggle-button" [hidden]="sidenav.opened">
      <mat-icon aria-label="Menu" class="material-icons">menu</mat-icon>
    </button>
    <h1 class="component-title">
      <a class="title-link" i18n="@@404MenuTitre">
        404, page not found</a>
    </h1>
    <span class="toolbar-filler"></span>
      <button id="button-logout" mat-button (click)="logout()" class="toolBarRight">
        <i class="material-icons">power_settings_new</i>
      </button>
  </mat-toolbar>
  <mat-sidenav-container class="example-sidenav-container">
                        
    <mat-sidenav #sidenav class="app-sidenav">   
      <mat-nav-list>     
        <mat-list-item class="sidenav-link" (click)="redirectToGenerateMenu(); ">            
          <mat-icon mat-list-icon>home</mat-icon>
          <h4 i18n="@@404MenuArea1" class="title" mat-line>Home</h4>
        </mat-list-item>
      </mat-nav-list> 
    </mat-sidenav>
    <mat-sidenav-content>
      <div classe="notFound">
        <div class="error">
          <div class="container-floud">
            <div class="container-error-404">
              <div  class="clip"><div class="shadow "><span #thirdDigit class="digit thirdDigit"></span></div></div>
              <div  class="clip"><div class="shadow "><span #secondDigit class="digit secondDigit" ></span></div></div>
              <div  class="clip"><div class="shadow "><span #firstDigit class="digit firstDigit" ></span></div></div>
              <div class="msg">OH!<span class="triangle"></span></div>
            </div>
            <span class="h2" i18n="@@404ContentSub">Sorry! Something went wrong, </span>
            <span class="h2">
              <a href="./index.html"><span class="h2 alink" i18n="@@404ContentSubLink">Back !</span></a>
            </span>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  
</div>


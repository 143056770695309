import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class JwtService implements HttpInterceptor {
    constructor() { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available

        if (localStorage.getItem("_token") != null && !request.url.includes("sharepoint.com")) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${localStorage.getItem("_token")}`,
                }
            });
        }
        return next.handle(request);
    }
}

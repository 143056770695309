import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar'
import { errorSnackBar, succesSnackBar } from '../services/tools'
import { HttpHandler } from '@angular/common/http';
import { apiFlowConfig } from '../../../ressources/config/api-flow.config'
import { tools } from '../services/tools';

import { AlertService } from '../services/alert.service';
import { SharedMenuService } from '../services/shared-menu.service';
import { url } from 'inspector';
import { Router } from '@angular/router';


@Component({
  selector: 'alert',
  template: ``
})

export class AlertComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  message: any;
  public iframeContent: string = "";
  private snackBarIsClosed: boolean = true;
  private SubscriptionSnackBarIsClosed: Subscription;

  constructor(private alertService: AlertService,
    public snackBar: MatSnackBar,
    private tools: tools,
    private router: Router,
    private sharedMenuService: SharedMenuService) {
    this.SubscriptionSnackBarIsClosed = sharedMenuService.snackBarIsClose$.subscribe(
      value => {
        this.snackBarIsClosed = value;
        console.log(this.snackBarIsClosed);
      }
    )
  }

  ngOnInit() {
    this.subscription = this.alertService.getMessage().subscribe(message => {
      let delay: number = 0;
      let messageDisplay: string = "";
      if (message != null && message.type == "error") {
        message = message.text;
        switch (message.subType) {
          case "timeOut":
            delay = 3500;
            messageDisplay = "Connexion au serveur impossible : Mode hors ligne";
            break;
          case "401":
            delay = 8000;
            messageDisplay = "Votre session a expiré, veuillez vous reconnecter"
            this.logout()
            break;

          case "404":
            delay = 8000;
            messageDisplay = "impossible d'accéder au service " + message.message + " Contacter le support";
            break;
          default:
            delay = 6500;
            if (message.message != null && message.message != "")
              messageDisplay = message.message
            else
              messageDisplay = message.toString();
            break;
        }
        if (delay != 0) {
          if (this.snackBarIsClosed == true)
            this.openSnackBar(messageDisplay, delay);
        }

      }
      else if (message != null && message.type == "success") {
        delay = 8000;
        messageDisplay = message.text
        this.openSnackBarSucces(messageDisplay, delay);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.SubscriptionSnackBarIsClosed.unsubscribe()
  }

  openSnackBar(message: string, delay: number) {
    this.snackBar.openFromComponent(errorSnackBar, {
      duration: delay,
      data: message
    });
  }


  public async logout() {
    if (apiFlowConfig.loginUrlForIframe != '') {
      localStorage.setItem("lastAppUrl", JSON.stringify(window.location.href))

      document.getElementsByTagName('iframe')[0].src = apiFlowConfig.loginUrlForIframe + "/logout"
      for (let i = 0; i < 25; i++) {
        await this.tools.delay(50);
        if (document.getElementsByTagName('iframe')[0].contentWindow.localStorage.getItem("userSession") == null) {
          window.location.href = apiFlowConfig.loginUrlForIframe;
          break;
        }
        else
          console.log(i)

      }
      window.location.href = apiFlowConfig.loginUrlForIframe;
    }
    else {
      this.sharedMenuService.setLogin(false);
      // this.router.navigate['/login'];
    }
  }



  openSnackBarSucces(message: string, delay: number) {
    this.snackBar.openFromComponent(succesSnackBar, {
      duration: delay,
      data: message
    });
  }
}



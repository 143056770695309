import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, ErrorHandler, Injectable, Inject } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { MaterialModule } from './material-module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ClickOutsideDirective } from './services/click-outside.directive'


import 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { } from 'hammerjs'
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout'
import { DatePipe } from '@angular/common';
import { ErrorHandlerService } from './services/error-handler.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HelpComponent } from './help/help.component';
import { AlertComponent } from './alert/alert.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { JwtService } from './drive-services/jwt.service';
import { UpdatePwaService } from './drive-services/update-pwa.service'
import { errorSnackBar, succesSnackBar } from './services/tools';
import { DisplayFilesComponent } from './display-files/display-files.component';
import { popupFilterText } from './display-files/popup-filter-text'
import { MenuComponent, bottomList } from './menu/menu.component';
import { DisplayJsonWebPageComponent } from './display-json-web-page/display-json-web-page.component';
import { ConfigureAppComponent } from './configure-app/configure-app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment, msalConfiguration } from '../environments/environment';
import { MsalModule, MsalInterceptor, MsalInterceptorConfiguration, MsalGuardConfiguration, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalService, MsalGuard, MsalBroadcastService, MsalRedirectComponent } from '@azure/msal-angular';
import { StorageModule } from '@ngx-pwa/local-storage'
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { updateService } from './services/updateService';
import { updateServicePopUp } from './services/updateServicePopUp';



registerLocaleData(localeFr);
export const protectedResourceMap: [string, string[]][] = [['https://graph.microsoft.com/v1.0/me/drive', ["Files.Read", "Files.Read.All", "Files.Read.Selected", "Files.ReadWrite", "Files.ReadWrite.All", "Files.ReadWrite.AppFolder", "Files.ReadWrite.Selected", "user.read", "offline_access"]],
];

@Injectable()
export class HammerConfig extends HammerGestureConfig {
    overrides = <any>{
        'pinch': { enable: false },
        'rotate': { enable: false },
        // 'swipe': {velocity: 0.4, pointers:1,threshold: 300},
        'pan': { velocity: 0.3, threshold: 30 }
    }
}

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log("loggerCallback", message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: msalConfiguration.clientID, // PPE testing environment
            authority: 'https://login.microsoftonline.com/common', // Prod environment. Uncomment to use.
            // authority: 'https://login.windows-ppe.net/common', // Prod environment. Uncomment to use.
            redirectUri: msalConfiguration.redirectUri,
            postLogoutRedirectUri: msalConfiguration.redirectUri
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: msalConfiguration.storeAuthStateInCookie,
        },
        system: {
            loggerOptions: {
                logLevel: LogLevel.Verbose,
                piiLoggingEnabled: msalConfiguration.piiLoggingEnabled
            }
        }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', msalConfiguration.consentScopes);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: msalConfiguration.consentScopes
        },
        loginFailedRoute: '/home'
    };
}

@NgModule({
    declarations: [
        AppComponent,
        HelpComponent,
        AlertComponent,
        errorSnackBar,
        succesSnackBar,
        ClickOutsideDirective,
        NotFoundComponent,
        bottomList,
        DisplayFilesComponent,
        MenuComponent,
        DisplayJsonWebPageComponent,
        ConfigureAppComponent,
        updateServicePopUp,
        popupFilterText,
    ],
    imports: [
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        BrowserModule,
        MaterialModule,
        BrowserAnimationsModule,
        HttpClientModule,        
        OAuthModule.forRoot(),
        MsalModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:10000'
        })
        // MsalModule.forRoot( new PublicClientApplication({
        //     auth: {
        //         clientId: msalConfiguration.clientID, // PPE testing environment
        //         authority: 'https://login.microsoftonline.com/common', // Prod environment. Uncomment to use.
        //         // authority: 'https://login.windows-ppe.net/common', // Prod environment. Uncomment to use.
        //         redirectUri: msalConfiguration.redirectUri
        //     },
        //     cache: {
        //         cacheLocation: BrowserCacheLocation.LocalStorage,
        //         storeAuthStateInCookie: msalConfiguration.storeAuthStateInCookie,
        //     },
        //     system: {
        //         loggerOptions: {
        //             loggerCallback,
        //             logLevel: LogLevel.Verbose,
        //             piiLoggingEnabled: msalConfiguration.piiLoggingEnabled
        //         }
        //     }
        // }), {
        //     interactionType: InteractionType.Redirect, // Msal Guard Configuration
        //     authRequest: {
        //         scopes: msalConfiguration.consentScopes
        //     }
        // }, null),
        // StorageModule.forRoot({ IDBNoWrap: true })
    ],
    exports: [RouterModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtService, multi: true },
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: DateAdapter, useClass: MomentDateAdapter, },
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
        { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
        { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        ClickOutsideDirective,
        DatePipe,
        updateService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

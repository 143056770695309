import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { environment } from '../environments/environment.prod'
import { updateService } from './services/updateService';
import { VersionCheckService } from './services/version-check.service'



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', '../assets/iconFont/material-icons.scss']
})
export class AppComponent implements OnInit {
  title = 'Mes fichiers Obexto';
  constructor(versionCheckService: VersionCheckService, updateService: updateService, private swUpdate: SwUpdate) {
    versionCheckService.initVersionCheck(environment.versionCheckURLDefault)
    updateService.checkForUpdate()
  }

  ngOnInit() {
  }

}

import { Injectable, OnInit } from '@angular/core';
import { Subject, Observable, } from 'rxjs';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { GetJsonObjectService } from '../services/get-json-object.service'
import { first, map, timeout } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StoreFileService implements OnInit {

  private fileEntrySource = new Subject<Map<string, fileEntryDetails>>();
  fileEntry$ = this.fileEntrySource.asObservable();
  public setFileEntry(fileEntry: Map<string, fileEntryDetails>) {
    this.fileEntrySource.next(fileEntry)
  }

  private fileDownloadEvolutionSource = new Subject<fileTransfertProgress>();
  fileDownloadEvolution$ = this.fileDownloadEvolutionSource.asObservable();
  public setFileDownloadEvolution(evolution: fileTransfertProgress) {
    this.fileDownloadEvolutionSource.next(evolution)
  }
  /*
  requirements
    Store in a local file the state of each json files (UID, IsUploaded, lastDownloadDate, )
  */
  private downloadInstanceCounter: number = 0
  private mapFileEntry: Map<string, fileEntryDetails>;


  constructor(private storage: LocalStorage, private getJsonObjectService: GetJsonObjectService) {
    // this.fileTransfer = this.transfer.create();
    this.mapFileEntry = new Map<string, fileEntryDetails>();
    this.readFEfromStorage();
  }

  ngOnInit() {
  }

  private readFEfromStorage() {

    this.storage.getItem("mapFileEntry", { type: 'string' })
      .subscribe((mapFileEntry) => {
        if (typeof (mapFileEntry) != (null || undefined))
          this.mapFileEntry = this.obj_to_map(JSON.parse(mapFileEntry));
        else
          this.mapFileEntry = null;
      })

  }

  public insertUpdateOneEntry(uidFile: string, newEntry: fileEntryDetails) {
    this.mapFileEntry.set(uidFile, newEntry);
    this.storeMap();
  }

  public insertUpdateMultipleEntry(uidFile: string[], newEntry: fileEntryDetails[]) {
    for (let i = 0; i < uidFile.length; i++) {
      this.mapFileEntry.set(uidFile[i], newEntry[i]);
    }
    this.storeMap();
  }

  public removeEntry(uidFile) {
    this.mapFileEntry.delete(uidFile);
    this.storeMap();
  }

  private storeMap() {
    //todo store map in fs
    let writeOptions = { replace: true, append: false }
    this.storage.setItem("mapFileEntry", JSON.stringify(this.map_to_obj(this.mapFileEntry)), { type: 'string' })
      .subscribe({
        next: (mapFileEntrySaveResult) => { console.log("SUCCES STORAGE : " + mapFileEntrySaveResult) },
        error: (mapFileEntrySaveResult) => { console.log("ERROR STORAGE : " + mapFileEntrySaveResult) }
      })
    this.setFileEntry(this.mapFileEntry)
  }

  public getFileEntryDetails(uidFile): fileEntryDetails {
    if (this.mapFileEntry != null)
      return this.mapFileEntry.get(uidFile);
    else
      return null;
  }

  public getfileEntryArray(): Map<string, fileEntryDetails> {
    return this.mapFileEntry;
  }

  public downloadFile(uidFile: string, downloadUrl: string, lastMofication: Date, parentId: string, name: string): Observable<any> {
    return this.downloadFileChild(uidFile, downloadUrl, lastMofication, parentId, name)
  }

  public downloadFileChild(uidFile: string, downloadUrl: string, lastMofication: Date, parentId: string, name: string): Observable<any> {
    this.downloadInstanceCounter = this.downloadInstanceCounter + 1;

    let httpOptions = {
      headers: new HttpHeaders({
      }),
      responseType: 'text' as 'json'
    };

    console.log("downloadFileChild :" + name)


    return this.getJsonObjectService.getJson(downloadUrl, httpOptions)
      .pipe(map(
        entry => {
          return this.storeFileString(uidFile, entry)
            .pipe(
              first()).subscribe(
                result => {
                  let newFE: fileEntryDetails =
                  {
                    lastModification: new Date(lastMofication),
                    downloadState: 3,
                    parentId: parentId,
                    fileType: 1,
                    name: name
                  }
                  this.insertUpdateOneEntry(uidFile, newFE)
                  this.downloadInstanceCounter = this.downloadInstanceCounter - 1;
                })

        }))
  }

  public removeFile(uidFile: string): Observable<any> {
    this.removeEntry(uidFile)
    return this.storage.removeItem(uidFile)
  }

  public storeFileObject(uidFile: string, fileContent: object): Observable<any> {
    return this.storage.setItem(uidFile, JSON.stringify(fileContent), { type: 'string' })
  }

  public storeFileString(uidFile: string, fileContent: string): Observable<any> {
    return this.storage.setItem(uidFile, fileContent, { type: 'string' })
  }

  public updatesFile(uidFile: string, downloadUrl: string, lastMofication: Date, parentId: string, name: string): Observable<any> {


    let httpOptions = {
      headers: new HttpHeaders({
      }),
      responseType: 'text' as 'json'
    };

    return this.getJsonObjectService.getJson(downloadUrl, httpOptions)
      .pipe(map(
        file => {
          if (file != null) {
            this.removeFile(uidFile)
              .pipe(
                first()).subscribe(
                  result => {
                    this.storage.setItem(uidFile, JSON.stringify(file), { type: 'string' })
                    let newFE: fileEntryDetails =
                    {
                      lastModification: new Date(lastMofication),
                      downloadState: 3,
                      parentId: parentId,
                      fileType: 1,
                      name: name
                    }
                    this.insertUpdateOneEntry(uidFile, newFE)
                    this.downloadInstanceCounter = this.downloadInstanceCounter - 1;
                  })
          }
          else {
            //reinitialise download state
            let fe: fileEntryDetails =
            {
              downloadState: 1,
              lastModification: new Date(),
              parentId: parentId,
              fileType: 1,
              name: name
            }
            this.insertUpdateOneEntry(uidFile, fe);
            let progression: fileTransfertProgress =
            {
              uidFile: uidFile,
              pourcentage: -1
            }
            this.setFileDownloadEvolution(progression)
          }
        }))
  }

  public readFileFromLocalPath(uidFile: string, path: string = ''): Observable<any> {
    return this.storage.getItem(uidFile, { type: 'string' })
  }

  /*
    return a file entry of the file (could be used to read data using filereader)
  */
  public getfileEntry(uidFileName: string): Observable<any> {
    return this.storage.getItem(uidFileName, { type: 'string' })
  }

  private map_to_obj(mm: any): Object {
    const obj = {};
    mm.forEach((v, k) => {
      obj[k] = JSON.stringify(v)
    });
    return obj
  }

  private obj_to_map(obj: any): any {
    let mm = new Map;
    Object.keys(obj).forEach(k => {
      mm.set(k, JSON.parse(obj[k]))
    })
    return mm
  }

  public removeDirectory() {
    //call delta ?
  }
}
const downloadState =
{
  1: "toDownload",
  2: "downloading",
  3: "downloaded"
}

const fileType =
{
  1: "file",
  2: "directory"
}

export interface fileEntryDetails {
  downloadState: number,
  lastModification: Date,
  parentId: string
  fileType: number,
  name: string
}

export interface fileTransfertProgress {
  uidFile: string,
  pourcentage: number
}
import { LOCALE_ID, NgModule, ErrorHandler, Injectable, Inject } from '@angular/core';
import { errorSnackBar } from './tools';
import {  MatSnackBarModule,  MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  //TODO delete trick LOCAL_ID for lang using i18n TS with angular 7
lang : string = "en";

constructor(private snackBar : MatSnackBar,
  @Inject(LOCALE_ID) locale: any) {
    if(locale == "fr")
      this.lang = "fr"
  }

handleError(error : any) {  
  try
  {
    if( !error.include("innerHTML")){
      let msg : string =""
      if(this.lang == "fr")
        msg = ": Contacter votre administrateur"
      else
        msg = ": contact your administrator"

      this.snackBar.openFromComponent(errorSnackBar, {
        duration: 8000,
        data: error + msg
      });
    }
  }
  catch
  {
    console.log("global catch message error");
    if(error != null)
    {
      console.log(error)
    }
  }
}
}

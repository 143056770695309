
import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  inputFilter: string;
}


@Component({
  selector: 'popup-filter-text',
  templateUrl: 'popup-filter-text.html',
})
export class popupFilterText {

  constructor(
    public dialogRef: MatDialogRef<popupFilterText>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpRequestService } from '../drive-services/http-request.service';
import { AuthServiceProvider } from './connect-to-drive/auth.service'
import { VerifyAccessService } from './connect-to-drive/verify-access.service'
import { filter, first } from 'rxjs/operators';
import { SharedMenuService } from '../services/shared-menu.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular'

import { element } from 'protractor';
import { Subscription } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { msalConfiguration } from 'src/environments/environment';
import { AuthenticationResult, EventMessage, EventType, PopupRequest, RedirectRequest } from '@azure/msal-browser';

@Component({
  selector: 'app-configure-app',
  templateUrl: './configure-app.component.html',
  styleUrls: ['./configure-app.component.scss']
})
export class ConfigureAppComponent implements OnInit, AfterViewInit {

  public isLoading: boolean = true;
  public isAccessToOneDriveValid: boolean = false;
  public userName: string;
  private subscription: Subscription;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private sharedMenuService: SharedMenuService,
    private cdRef: ChangeDetectorRef,
    private VerifyAccessService: VerifyAccessService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService) {
    console.log("ConfigureAppComponent constructor")
  }

  ngOnInit() {

    this.sharedMenuService.setAppTitle("Paramètre applicatif");
    this.isLoading = false
    //TODO simply update verifyAcces methode
    this.msalBroadcastService.msalSubject$.pipe(
      // filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || msg.eventType === EventType.ACCOUNT_ADDED),
    ).subscribe((result: EventMessage) => {
      let accounts = this.msalService.instance.getAllAccounts();
      if (accounts != null && accounts.length > 0) {
        this.isLoading = false
        this.isAccessToOneDriveValid = true;
        this.VerifyAccessService.verifyAcces()
        this.userName = accounts[0].username;
      }
    })
  }

  ngAfterViewInit(): void {
    this.VerifyAccessService.verifyAcces()
      .pipe(first())
      .subscribe(result => {
        this.isLoading = false
        if (result == true)
          this.isAccessToOneDriveValid = true;
          this.userName = localStorage.getItem("userName");
      }
      )
    this.userName = localStorage.getItem("userName");
  }

  ngOnDestroy() {
  }

  public configureOneDrive() {
    let loginParameters: RedirectRequest =
    {
      scopes: msalConfiguration.consentScopes
    }
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  public disconnectOneDrive() {
    this.msalService.logoutRedirect()
  }
}
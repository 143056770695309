import { Injectable, } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { map, timeout, catchError, retry } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { apiFlowConfig } from '../../../ressources/config/api-flow.config';
import { AlertService } from '../services/alert.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class GetJsonObjectService {

  private url: string = "";

  constructor(
    private http: HttpClient,
    private alertService: AlertService
  ) { }

  public dataOutput;

  // getJson(url: string): Promise<any> {
  //   let promise = new Promise((resolve, reject) => {
  //     let option = { params: null }
  //     let promise: Promise<any> = null;

  //     this.http.get(url, option)
  //       .toPromise()
  //       .then(
  //         data => {
  //           console.log("data read in get http", data)
  //           this.dataOutput = data;
  //           resolve();
  //         },
  //         msg => {
  //           reject(msg);
  //         }
  //       );
  //   });
  //   return promise;
  // }

  getJson(url: string, option: any): Observable<any> {
    return this.http.get(url, option)
      .pipe(
        map(
          data => {
            this.dataOutput = data;
            return data
          }))
  }

  public getJsonValue(): any {
    return this.dataOutput;
  }


  private handleError() {
    console.log(this.dataOutput)
    return of(this.dataOutput);
  }
}

